/* eslint-disable sort-keys */
export default class RegistrationData {
  code = "";

  keepInTouchEmail = "";

  keepInTouchTelephone = "";

  keepInTouchTextMessage = "";

  keepInTouchPost = "";

  eligible = true;

  registered;

  birthdate = "";

  placeOfBirth = "";

  bmiUnit = process.env.GATSBY_SITE === "gb" ? "imperial" : "metric";

  weightMetric = "";

  heightMetric = "";

  weightImperialSt = "";

  weightImperialLbs = "";

  heightImperialFeet = "";

  heightImperialInch = "";

  gender = "";

  salutation = "";

  lastname = "";

  firstname = "";

  middlename = "";

  street = "";

  houseNo = "";

  locality = "";

  district = "";

  postCode = "";

  city = "";

  county = "";

  state = "";

  apartment = "";

  houseName = "";

  po = false;

  poBox = "";

  poCity = "";

  poState = "";

  poPostCode = "";

  careOfAddress = "";

  area = "";

  additionalAddress = "";

  emailAddress = "";

  additionalEmailAddress = "";

  mobileNumber = "";

  additionalPhoneNumber = "";

  whatsApp = "";

  ethnicity = "";

  ethnicityLabel = "";

  gpPracticeName = "";

  gpCity = "";

  gpPhoneNumber = "";

  howDidYouHear = "";

  // US

  socialSecurityNumber = "";

  ancestry = {};

  ancestryOther = {};

  contactFirstname = "";

  contactLastname = "";

  contactPhoneNumber = "";

  contactEmailAddress = "";

  contactRelationship = "";

  // IN

  motherTongue = "";

  motherTongueMother = "";

  motherTongueFather = "";

  stateFather = "";

  stateMother = "";

  secondContactFirstname = "";

  secondContactLastname = "";

  secondContactPhoneNumber = "";

  secondContactEmailAddress = "";

  secondContactRelationship = "";

  // CL

  rutRun = "";

  ethnicityFather = "";

  ethnicityMother = "";

  info = "";

  region = "";

  // DE

  relationshipType = "";

  relationshipTypeLabel = "";

  relationshipFirstname = "";

  relationshipLastname = "";

  rootsId = "";

  activityInformation = false;

  personalizedNewsletter = false;

  // ZA

  suburb = "";

  passportNo = "";

  landlineNumber = "";

  yourEthnicity = "";

  yourNativeLanguage = "";

  countryFather = "";

  countryMother = "";

  deliveryBuilding = "";

  deliveryStreet = "";

  deliveryHouseNo = "";

  deliveryCity = "";

  deliverySuburb = "";

  deliveryPostCode = "";

  province = "";

  deliveryProvince = "";

  deliveryInstructions = "";

  postalAddress = false;

  deliveryAddress = false;

  personOneRelationShip = "";

  personOneFirstname = "";

  personOneLastname = "";

  personOneMobileNumber = "";

  personOneEmailAddress = "";

  personTwoRelationShip = "";

  personTwoFirstname = "";

  personTwoLastname = "";

  personTwoMobileNumber = "";

  personTwoEmailAddress = "";

  familyAgreement = "";

  newsletter = "";

  pregnant = false; // only checkbox

  estimatedTime = "";

  createAccount = process.env.GATSBY_REGISTRATION_ACCOUNT_ENABLED && process.env.GATSBY_REGISTRATION_ACCOUNT_ENABLED.toLowerCase() === "true";
}
